import React from 'react';
import { LayoutDashboard, PlusCircle, History, LogOut, Trash2 } from 'lucide-react';
import { useClerk } from '@clerk/clerk-react';
import { SavedDashboard } from '../types/metrics';
import { format, parseISO } from 'date-fns';

interface SidebarProps {
  savedDashboards: SavedDashboard[];
  onDashboardSelect: (dashboard: SavedDashboard) => void;
  onNewDashboard: () => void;
  onDashboardDelete?: (id: string) => void;
  activeDashboardId?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  savedDashboards,
  onDashboardSelect,
  onNewDashboard,
  onDashboardDelete,
  activeDashboardId,
}) => {
  const { signOut } = useClerk();

  return (
    <div className="flex flex-col w-64 bg-white border-r border-gray-200">
      <div className="flex items-center justify-between p-8">
        <div className="flex items-center justify-center w-full">
          <img src="/assets/images/dashling-logo.png" alt="Dashling" className="h-10" />
        </div>
      </div>
      
      <div className="p-4">
        <button
          onClick={onNewDashboard}
          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <PlusCircle className="w-4 h-4 mr-2" />
          New Dashboard
        </button>
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="px-4 py-2">
          <h3 className="flex items-center text-xs font-semibold text-gray-500 uppercase tracking-wider">
            <History className="w-4 h-4 mr-1" />
            Recent Dashboards
          </h3>
        </div>
        <nav className="space-y-1 px-2">
          {savedDashboards.map((dashboard) => (
            <div
              key={dashboard.id}
              className={`w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                activeDashboardId === dashboard.id
                  ? 'bg-indigo-50/75 text-indigo-600'
                  : 'text-gray-600 hover:bg-gray-50/75 hover:text-gray-900'
              }`}
            >
              <button
                onClick={() => onDashboardSelect(dashboard)}
                className="flex-1 flex items-center text-left"
              >
                <span className="truncate">{dashboard.name}</span>
                <span className="ml-auto text-xs text-gray-400">
                  {format(parseISO(dashboard.createdAt), 'MMM d')}
                </span>
              </button>
              {onDashboardDelete && (
                <button
                  onClick={() => onDashboardDelete(dashboard.id)}
                  className="ml-2 p-1 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-red-600"
                  title="Delete dashboard"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="mt-auto p-4 border-t border-gray-200">
        <button
          onClick={() => signOut()}
          className="w-full flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md"
        >
          <LogOut className="w-4 h-4 mr-2" />
          Sign Out
        </button>
      </div>
    </div>
  );
};