// Map of possible CSV field names to our standardized field names
export const CSV_FIELD_MAPPINGS = {
  // Ad Set Name variations
  'Ad Set Name': 'ad_set_name',
  'Ad set name': 'ad_set_name',
  'Ad set name': 'ad_set_name',
  'Adset name': 'ad_set_name',
  'Campaign name': 'ad_set_name',
  
  // Results variations
  'Link clicks': 'results',
  'Link Clicks': 'results',
  
  // Amount spent variations
  'Amount spent (DKK)': 'amount_spent',
  'Amount spent': 'amount_spent',
  'Spend (DKK)': 'amount_spent',
  
  // Impressions variations
  'Impressions': 'impressions',
  'impressions': 'impressions',
  'Total impressions': 'impressions',
  
  // Cost per result variations
  'Cost per results': 'cost_per_results',
  'CPC (cost per link click) (DKK)': 'cost_per_results',
  'CPC (all) (DKK)': 'cost_per_results',
  
  // Reach variations
  'Reach': 'reach',
  'reach': 'reach',
  'Total reach': 'reach',
  'Unique users': 'reach',

  // CTR variations
  'CTR (link click-through rate)': 'ctr',
  'CTR (link click-through rate) (%)': 'ctr',
  'CTR (all)': 'ctr',

  // Date variations
  'Reporting starts': 'reporting_starts',
  'reporting starts': 'reporting_starts',
  'Start date': 'reporting_starts',
  'Date start': 'reporting_starts',
  
  'Reporting ends': 'reporting_ends',
  'End date': 'reporting_ends',
  'Date end': 'reporting_ends'
};

// Helper to normalize field names by removing case sensitivity and extra spaces
export const normalizeFieldName = (header: string): string => {
  const normalized = header.trim();
  return CSV_FIELD_MAPPINGS[normalized] || normalized;
};