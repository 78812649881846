import React from 'react';
import { MetricsChart } from './MetricsChart';
import { MetricsTable } from './MetricsTable';
import { DashboardData, ChartType, MetricType } from '../types/metrics';
import { DollarSign, MousePointerClick, Users, Percent, CreditCard } from 'lucide-react';

interface SharedDashboardProps {
  data: DashboardData;
  dashboardName?: string;
}

export const SharedDashboard: React.FC<SharedDashboardProps> = ({ data, dashboardName }) => {
  const [chartType, setChartType] = React.useState<ChartType>('line');
  const [selectedMetrics, setSelectedMetrics] = React.useState<MetricType[]>([
    'impressions',
    'results',
    'amount_spent',
    'ctr'
  ]);

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <img src="/assets/images/dashling-logo.png" alt="Dashling" className="h-8" />
            <h1 className="text-xl font-semibold text-gray-900">{dashboardName || 'Shared Dashboard'}</h1>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex items-center gap-2 mb-2">
                <DollarSign className="w-5 h-5 text-indigo-600" />
                <h3 className="text-xs font-medium text-gray-900">Total Spend</h3>
              </div>
              <p className="text-sm md:text-base font-bold text-indigo-600 text-center">
                {data.summary.totalAmountSpent.toFixed(2)} DKK
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex items-center gap-2 mb-2">
                <MousePointerClick className="w-5 h-5 text-emerald-600" />
                <h3 className="text-xs font-medium text-gray-900">Total Results</h3>
              </div>
              <p className="text-sm md:text-base font-bold text-emerald-600 text-center">
                {data.summary.totalResults.toLocaleString()} clicks
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex items-center gap-2 mb-2">
                <Users className="w-5 h-5 text-blue-600" />
                <h3 className="text-xs font-medium text-gray-900">Total Reach</h3>
              </div>
              <p className="text-sm md:text-base font-bold text-blue-600 text-center">
                {data.summary.totalReach.toLocaleString()}
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex items-center gap-2 mb-2">
                <Percent className="w-5 h-5 text-amber-600" />
                <h3 className="text-xs font-medium text-gray-900">Average CTR</h3>
              </div>
              <p className="text-sm md:text-base font-bold text-amber-600 text-center">
                {((data.summary.totalResults / data.summary.totalImpressions) * 100).toFixed(2)}%
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex items-center gap-2 mb-2">
                <CreditCard className="w-5 h-5 text-purple-600" />
                <h3 className="text-xs font-medium text-gray-900">Average CPC</h3>
              </div>
              <p className="text-sm md:text-base font-bold text-purple-600 text-center">
                {(data.summary.totalAmountSpent / data.summary.totalResults).toFixed(2)} DKK
              </p>
            </div>
          </div>

          <MetricsChart
            data={data.metrics}
            chartType={chartType}
            metrics={selectedMetrics}
            onChartTypeChange={setChartType}
            onMetricToggle={(metric) => {
              setSelectedMetrics(prev =>
                prev.includes(metric)
                  ? prev.filter(m => m !== metric)
                  : [...prev, metric]
              );
            }}
          />

          <MetricsTable data={data.metrics} />
        </div>
      </main>
      <div className="bg-indigo-600 py-8 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">
            Want to create your own beautiful dashboards?
          </h2>
          <a
            href="/"
            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-lg text-indigo-600 bg-white hover:bg-indigo-50 transition-colors"
          >
            Try Dashling Free
          </a>
        </div>
      </div>
    </div>
  );
};