import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef
} from '@tanstack/react-table';
import { MetaAdMetrics } from '../types/metrics';
import { format, parseISO } from 'date-fns';

const columnHelper = createColumnHelper<MetaAdMetrics>();

const calculateTotals = (data: MetaAdMetrics[]) => ({
  reporting_starts: '',
  reporting_ends: '',
  ad_set_name: 'Total',
  ad_set_delivery: '',
  bid: 0,
  bid_type: '',
  ad_set_budget: 0,
  ad_set_budget_type: '',
  last_significant_edit: '',
  attribution_setting: '',
  results: data.reduce((sum, row) => sum + row.results, 0),
  result_indicator: '',
  reach: data.reduce((sum, row) => sum + row.reach, 0),
  impressions: data.reduce((sum, row) => sum + row.impressions, 0),
  cost_per_results: data.reduce((sum, row) => sum + row.cost_per_results, 0) / data.length,
  amount_spent: data.reduce((sum, row) => sum + row.amount_spent, 0),
  ends: '',
  starts: '',
});

const columns = [
  columnHelper.accessor('reporting_starts', {
    header: 'Date',
    cell: info => {
      const date = info.getValue();
      if (info.row.original.ad_set_name === 'Total') return '';
      return date ? format(parseISO(date), 'MMM d, yyyy') : 'Unknown Date';
    },
  }),
  columnHelper.accessor('ad_set_name', {
    header: 'Ad Set',
  }),
  columnHelper.accessor('impressions', {
    header: 'Impressions',
    cell: info => info.getValue().toLocaleString(),
  }),
  columnHelper.accessor('results', {
    header: 'Results',
    cell: info => `${info.getValue().toLocaleString()} clicks`,
  }),
  columnHelper.accessor('amount_spent', {
    header: 'Amount Spent',
    cell: info => `${info.getValue().toFixed(2)} DKK`,
  }),
  columnHelper.accessor('cost_per_results', {
    header: 'Cost per Click',
    cell: info => `${info.getValue().toFixed(2)} DKK`,
  }),
  columnHelper.accessor(row => (row.results / row.impressions) * 100, {
    id: 'ctr',
    header: 'CTR',
    cell: info => {
      const value = info.getValue();
      if (isNaN(value)) return '0.00%';
      return `${value.toFixed(2)}%`;
    },
  }),
];

interface MetricsTableProps {
  data: MetaAdMetrics[];
}

export const MetricsTable: React.FC<MetricsTableProps> = ({ data }) => {
  const tableData = React.useMemo(() => {
    const totals = calculateTotals(data);
    return [...data, totals];
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="overflow-x-auto">
        {data.length === 0 ? (
          <p className="text-gray-500 text-center py-8">No data available</p>
        ) : (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="px-2 md:px-4 lg:px-6 py-2 md:py-3 text-left text-[10px] md:text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.getRowModel().rows.map(row => (
              <tr 
                key={row.id}
                className={row.original.ad_set_name === 'Total' ? 'bg-gray-50 font-medium' : undefined}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className="px-2 md:px-4 lg:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </div>
    </div>
  );
};