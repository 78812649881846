import React, { useState, useRef, useEffect } from 'react';
import { Share2, ExternalLink, Copy, Check } from 'lucide-react';

interface ShareDropdownProps {
  getShareUrl: () => string;
}

export const ShareDropdown: React.FC<ShareDropdownProps> = ({ getShareUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const shareUrl = React.useMemo(() => getShareUrl(), [getShareUrl]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
      >
        <Share2 className="w-4 h-4 mr-2" />
        Share Dashboard
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="p-4">
            <div className="flex items-center space-x-2 mb-4">
              <input
                aria-label="Share URL"
                type="text"
                value={shareUrl}
                readOnly
                className="flex-1 p-2 border rounded-md text-sm bg-gray-50 select-all"
              />
              <button
                onClick={handleCopy}
                className="p-2 text-gray-500 hover:text-gray-700"
                title={copied ? 'Copied!' : 'Copy to clipboard'}
              >
                {copied ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
              </button>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => window.open(shareUrl, '_blank')}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                <ExternalLink className="w-4 h-4 mr-1" />
                Open in new tab
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};