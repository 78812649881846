import React, { useEffect, useState } from 'react';
import { DashboardLayout } from './DashboardLayout';
import { FileUpload } from './FileUpload';
import { MetricsChart } from './MetricsChart';
import { MetricsTable } from './MetricsTable';
import { ShareDropdown } from './ShareDropdown';
import { PDFReport } from './PDFReport';
import { ErrorAlert } from './ErrorAlert';
import { parseCSV } from '../utils/csvParser';
import { DashboardData, MetaAdMetrics, SavedDashboard, ChartType, MetricType } from '../types/metrics';
import { FileText, Download, DollarSign, MousePointerClick, Users, Percent, CreditCard } from 'lucide-react';
import { STORAGE_KEY } from '../App';

export const Dashboard: React.FC = () => {
  const [data, setData] = useState<DashboardData | null>(null);
  const [showPDF, setShowPDF] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savedDashboards, setSavedDashboards] = useState<SavedDashboard[]>([]);
  const [activeDashboardId, setActiveDashboardId] = useState<string>();
  const [chartType, setChartType] = useState<ChartType>('line');
  const [selectedMetrics, setSelectedMetrics] = useState<MetricType[]>([
    'impressions',
    'results',
    'amount_spent',
    'ctr'
  ]);

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [saveName, setSaveName] = useState('');

  useEffect(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      setSavedDashboards(JSON.parse(stored));
    }
  }, []);

  const saveDashboard = (name: string) => {
    const newDashboard: SavedDashboard = {
      id: Date.now().toString(),
      name,
      createdAt: new Date().toISOString(),
      data: data!,
      preferences: {
        chartType,
        metrics: selectedMetrics,
      },
    };

    const updated = [...savedDashboards, newDashboard];
    setSavedDashboards(updated);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
    setActiveDashboardId(newDashboard.id);
  };

  const handleDashboardSelect = (dashboard: SavedDashboard) => {
    setData(dashboard.data);
    setChartType(dashboard.preferences.chartType);
    setSelectedMetrics(dashboard.preferences.metrics);
    setActiveDashboardId(dashboard.id);
    setShowPDF(false);
  };

  const handleNewDashboard = () => {
    setData(null);
    setActiveDashboardId(undefined);
    setChartType('line');
    setSelectedMetrics(['impressions', 'results', 'amount_spent']);
  };

  const handleDashboardDelete = (id: string) => {
    const updated = savedDashboards.filter(d => d.id !== id);
    setSavedDashboards(updated);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
    
    if (id === activeDashboardId) {
      handleNewDashboard();
    }
  };

  const handleFileSelect = async (file: File) => {
    try {
      setError(null);
      const metrics = await parseCSV(file);
      const summary = calculateSummary(metrics);
      setData({ metrics, summary });
    } catch (error) {
      console.error('Error parsing CSV:', error);
      setError(error instanceof Error ? error.message : 'Failed to parse CSV file');
      setData(null);
    }
  };

  const calculateSummary = (metrics: MetaAdMetrics[]) => {
    const totalAmountSpent = metrics.reduce((sum, m) => sum + m.amount_spent, 0);
    const totalImpressions = metrics.reduce((sum, m) => sum + m.impressions, 0);
    const totalResults = metrics.reduce((sum, m) => sum + m.results, 0);
    const totalReach = metrics.reduce((sum, m) => sum + m.reach, 0);

    return {
      totalAmountSpent,
      totalImpressions,
      totalResults,
      totalReach,
      averageCostPerResult: totalAmountSpent / totalResults,
      averageCPM: (totalAmountSpent / totalImpressions) * 1000,
    };
  };

  return (
    <>
      <DashboardLayout
        savedDashboards={savedDashboards}
        onDashboardSelect={handleDashboardSelect}
        onNewDashboard={handleNewDashboard}
        onDashboardDelete={handleDashboardDelete}
        activeDashboardId={activeDashboardId}
      >
        {data && <div className="flex justify-between items-center mb-8">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900">
            {savedDashboards.find(d => d.id === activeDashboardId)?.name || (data ? 'Untitled Dashboard' : '')}
          </h1>
          <div className="flex items-center space-x-4">
            {data && !showPDF && (
              <ShareDropdown
                getShareUrl={() => {
                  const url = new URL(window.location.href);
                  url.searchParams.set('view', 'shared');
                  url.searchParams.set('data', btoa(JSON.stringify(data)));
                  return url.toString();
                }}
              />
            )}
            {data && !showPDF && !activeDashboardId && (
              <button
                onClick={() => setShowSaveDialog(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
              >
                Save Dashboard
              </button>
            )}
            {data && !showPDF && (
              <button
                onClick={() => setShowPDF(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                <Download className="w-4 h-4 mr-2" />
                Download PDF Report
              </button>
            )}
            {showPDF && (
              <button
                onClick={() => setShowPDF(false)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                <FileText className="w-4 h-4 mr-2" />
                Back to Dashboard
              </button>
            )}
          </div>
        </div>}

        {!data && (
          <>
            {error && <ErrorAlert message={error} onDismiss={() => setError(null)} />}
            <FileUpload onFileSelect={handleFileSelect} />
          </>
        )}

        {data && !showPDF && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="w-5 h-5 text-indigo-600" />
                  <h3 className="text-xs font-medium text-gray-900">Total Spend</h3>
                </div>
                <p className="text-sm md:text-base font-bold text-indigo-600 text-center">
                  {data.summary.totalAmountSpent.toFixed(2)} DKK
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-center gap-2 mb-2">
                  <MousePointerClick className="w-5 h-5 text-emerald-600" />
                  <h3 className="text-xs font-medium text-gray-900">Total Results</h3>
                </div>
                <p className="text-sm md:text-base font-bold text-emerald-600 text-center">
                  {data.summary.totalResults.toLocaleString()} clicks
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Users className="w-5 h-5 text-blue-600" />
                  <h3 className="text-xs font-medium text-gray-900">Total Reach</h3>
                </div>
                <p className="text-sm md:text-base font-bold text-blue-600 text-center">
                  {data.summary.totalReach.toLocaleString()}
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Percent className="w-5 h-5 text-amber-600" />
                  <h3 className="text-xs font-medium text-gray-900">Average CTR</h3>
                </div>
                <p className="text-sm md:text-base font-bold text-amber-600 text-center">
                  {((data.summary.totalResults / data.summary.totalImpressions) * 100).toFixed(2)}%
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-center gap-2 mb-2">
                  <CreditCard className="w-5 h-5 text-purple-600" />
                  <h3 className="text-xs font-medium text-gray-900">Average CPC</h3>
                </div>
                <p className="text-sm md:text-base font-bold text-purple-600 text-center">
                  {(data.summary.totalAmountSpent / data.summary.totalResults).toFixed(2)} DKK
                </p>
              </div>
            </div>

            <MetricsChart
              data={data.metrics}
              chartType={chartType}
              metrics={selectedMetrics}
              onChartTypeChange={setChartType}
              onMetricToggle={(metric) => {
                setSelectedMetrics(prev =>
                  prev.includes(metric)
                    ? prev.filter(m => m !== metric)
                    : [...prev, metric]
                );
              }}
            />

            <MetricsTable data={data.metrics} />
          </div>
        )}

        {data && showPDF && (
          <PDFReport 
            data={data} 
            dashboardName={savedDashboards.find(d => d.id === activeDashboardId)?.name}
            chartType={chartType}
            selectedMetrics={selectedMetrics}
          />
        )}
      </DashboardLayout>

      {showSaveDialog && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Save Dashboard</h3>
            <input
              type="text"
              value={saveName}
              onChange={(e) => setSaveName(e.target.value)}
              placeholder="Enter dashboard name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowSaveDialog(false);
                  setSaveName('');
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (saveName.trim()) {
                    saveDashboard(saveName);
                    setShowSaveDialog(false);
                    setSaveName('');
                  }
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};