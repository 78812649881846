import React from 'react';
import { SignUpButton } from '@clerk/clerk-react';
import { Link } from './Link';
import { Footer } from './Footer';
import { Header } from './Header';
import { BarChart3, LineChart, TrendingUp, Share2, FileText, Database, Check, ArrowRight, BarChart } from 'lucide-react';

export const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />

      {/* Hero Section */}
      <div className="relative overflow-hidden pt-32">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-y-0 w-full h-full bg-[linear-gradient(120deg,#e0e7ff_0%,#f5f3ff_50%,#dbeafe_100%)] animate-gradient-x"></div>
          <div className="absolute inset-0 bg-[radial-gradient(#4f46e5_1px,transparent_1px)] [background-size:32px_32px] [background-position:center] opacity-[0.25]"></div>
          <div className="absolute inset-0 bg-[radial-gradient(#4f46e5_0.75px,transparent_0.75px)] [background-size:16px_16px] [background-position:center] opacity-[0.2] animate-fade-in"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-white/90"></div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-36 text-center relative">
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold text-gray-900 mb-8 tracking-tight">
            Significantly
            <span className="block mt-2 bg-gradient-to-r from-indigo-600 to-blue-600 text-transparent bg-clip-text leading-tight">
              improve insights
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 mb-12 max-w-2xl mx-auto leading-relaxed">
            Turn your Meta Ads data into beautiful, interactive visualizations in seconds.
            Make data-driven decisions with confidence.
            <span className="block mt-2 text-gray-500">No credit card required.</span>
          </p>
          <div className="flex items-center justify-center space-x-6">
            <SignUpButton mode="modal">
              <button className="group inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 transform transition-all hover:scale-105">
                Start for free
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
            </SignUpButton>
          </div>
          
          {/* Stats */}
          <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="bg-white/60 backdrop-blur-sm rounded-xl p-6 shadow-sm border border-gray-100">
              <div className="text-4xl font-bold text-indigo-600 mb-2">Real-time</div>
              <div className="text-gray-600">Analytics</div>
            </div>
            <div className="bg-white/60 backdrop-blur-sm rounded-xl p-6 shadow-sm border border-gray-100">
              <div className="text-4xl font-bold text-indigo-600 mb-2">1-Click</div>
              <div className="text-gray-600">PDF reports</div>
            </div>
            <div className="bg-white/60 backdrop-blur-sm rounded-xl p-6 shadow-sm border border-gray-100">
              <div className="text-4xl font-bold text-indigo-600 mb-2">Unlimited</div>
              <div className="text-gray-600">Dashboards</div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 bg-gray-50/50">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
              <BarChart3 className="w-6 h-6 text-indigo-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Visual Analytics</h3>
            <p className="text-gray-600">
              Interactive charts and graphs that make your data easy to understand and analyze.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-emerald-100 rounded-lg flex items-center justify-center mb-4">
              <TrendingUp className="w-6 h-6 text-emerald-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Performance Metrics</h3>
            <p className="text-gray-600">
              Track key metrics like CTR, CPC, and ROI with real-time updates and historical data.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
              <Share2 className="w-6 h-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Easy Sharing</h3>
            <p className="text-gray-600">
              Share your dashboards with team members and stakeholders with just one click.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-amber-100 rounded-lg flex items-center justify-center mb-4">
              <FileText className="w-6 h-6 text-amber-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">PDF Reports</h3>
            <p className="text-gray-600">
              Generate professional PDF reports with your analytics data for meetings and presentations.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-purple-100 rounded-lg flex items-center justify-center mb-4">
              <Database className="w-6 h-6 text-purple-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Data Storage</h3>
            <p className="text-gray-600">
              Save and organize multiple dashboards with automatic cloud backup and sync.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="w-10 h-10 bg-pink-100 rounded-lg flex items-center justify-center mb-4">
              <LineChart className="w-6 h-6 text-pink-600" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Smart Filters</h3>
            <p className="text-gray-600">
              Filter and analyze your data by date ranges, campaigns, and key metrics.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-indigo-600 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center pb-24">
          <h2 className="text-3xl font-bold text-white mb-4">
            Ready to unlock your Meta Ads insights?
          </h2>
          <p className="text-lg text-indigo-100 mb-6">
            Join marketers who use Dashling to make better decisions.
          </p>
          <SignUpButton mode="modal">
            <button className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg text-indigo-600 bg-white hover:bg-indigo-50">
              Get Started Free
            </button>
          </SignUpButton>
        </div>
      </div>
      <Footer />
    </div>
  );
};