import React from 'react';
import { BarChart, Bar, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MetaAdMetrics, ChartType, MetricType } from '../types/metrics';
import { format, parseISO } from 'date-fns';
import { BarChart3, LineChart as LineChartIcon, TrendingUp, DollarSign, MousePointerClick, Users, Percent, CreditCard } from 'lucide-react';

export const METRIC_COLORS = {
  impressions: '#427DDC', // Indigo
  results: '#85DDB7', // Emerald
  amount_spent: '#F25F5F', // Red
  reach: '#D9E5F8', // Blue
  cost_per_results: '#913AF6', // Purple
  ctr: '#FFA40A', // Amber
  cpc: '#DB2777', // Pink
};

const METRIC_LABELS = {
  impressions: 'Impressions',
  results: 'Results (Clicks)',
  amount_spent: 'Amount Spent DKK',
  reach: 'Reach',
  cost_per_results: 'Cost per Click DKK',
  ctr: 'Click-through Rate',
  cpc: 'Cost per Click DKK',
};

const CHART_TYPES = [
  { type: 'line' as const, icon: <LineChartIcon className="w-4 h-4" />, label: 'Line' },
  { type: 'bar' as const, icon: <BarChart3 className="w-4 h-4" />, label: 'Bar' },
  { type: 'area' as const, icon: <TrendingUp className="w-4 h-4" />, label: 'Area' },
];

const AVAILABLE_METRICS = [
  { value: 'impressions' as const, label: 'Impressions' },
  { value: 'results' as const, label: 'Results' },
  { value: 'amount_spent' as const, label: 'Amount Spent' },
  { value: 'reach' as const, label: 'Reach' },
  { value: 'ctr' as const, label: 'CTR' },
  { value: 'cpc' as const, label: 'CPC' },
];

interface MetricsChartProps {
  data: MetaAdMetrics[];
  chartType: ChartType;
  metrics: MetricType[];
  onChartTypeChange: (type: ChartType) => void;
  onMetricToggle: (metric: MetricType) => void;
}

const formatLargeNumber = (value: number) => {
  if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
  if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
  return value.toString();
};

export const MetricsChart: React.FC<MetricsChartProps> = ({
  data,
  chartType,
  metrics,
  onChartTypeChange,
  onMetricToggle,
}) => {
  const formattedData = data.map(item => ({
    campaign: item.ad_set_name || 'Unknown Campaign',
    impressions: item.impressions,
    results: item.results,
    amount_spent: item.amount_spent,
    reach: item.reach,
    ctr: (item.results / item.impressions) * 100,
    cpc: item.amount_spent / item.results || 0
  }));

  const renderChart = () => {
    const commonProps = {
      data: formattedData,
      margin: { top: 20, right: 30, left: 20, bottom: 40 },
    };

    const commonAxisProps = {
      xAxis: <XAxis 
        dataKey="campaign" 
        height={60}
        interval={0}
        tick={({ x, y, payload }) => {
          const words = payload.value.split(' ');
          const lines = [];
          let currentLine = '';
          
          words.forEach(word => {
            const testLine = currentLine ? `${currentLine} ${word}` : word;
            if (testLine.length <= 15) {
              currentLine = testLine;
            } else {
              if (currentLine) lines.push(currentLine);
              currentLine = word;
            }
          });
          if (currentLine) lines.push(currentLine);
          
          return (
            <g transform={`translate(${x},${y})`}>
              {lines.map((line, i) => (
                <text
                  key={i}
                  x={0}
                  y={i * 12 + 12}
                  dy={4}
                  textAnchor="middle"
                  fill="#666"
                  fontSize={10}
                >
                  {line}
                </text>
              ))}
            </g>
          );
        }}
      />,
      yAxis: [
        <YAxis
          key="left"
          yAxisId="left"
          orientation="left"
          tickFormatter={formatLargeNumber}
          domain={[0, 'auto']}
        />,
        <YAxis
          key="right"
          yAxisId="right"
          orientation="right"
          domain={[0, 'auto']}
        />
      ],
    };

    const renderMetric = (metric: MetricType, ChartComponent: typeof Line | typeof Bar | typeof Area) => {
      return metrics.includes(metric) && (
        <ChartComponent
          key={metric}
          type="monotone"
          yAxisId={metric === 'impressions' ? 'left' : 'right'}
          dataKey={metric}
          name={METRIC_LABELS[metric]}
          stroke={METRIC_COLORS[metric]}
          fill={METRIC_COLORS[metric]}
          fillOpacity={chartType === 'area' ? 0.1 : 1}
          maxBarSize={40}
        />
      );
    };

    if (chartType === 'line') {
      return (
        <LineChart {...commonProps}>
          <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
          {commonAxisProps.xAxis}
          {commonAxisProps.yAxis}
          <Tooltip
            formatter={(value: any, name: string) => [value.toLocaleString(), name]}
            contentStyle={{ backgroundColor: 'white', borderRadius: '6px', border: '1px solid #e5e7eb' }}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            wrapperStyle={{ bottom: 50 }}
            verticalAlign="bottom"
            height={36}
          />
          {Object.keys(METRIC_COLORS)
            .filter((metric): metric is MetricType => metrics.includes(metric as MetricType))
            .map((metric) => (
              <Line
                key={metric}
                type="monotone"
                yAxisId={metric === 'impressions' ? 'left' : 'right'}
                dataKey={metric}
                name={METRIC_LABELS[metric]}
                stroke={METRIC_COLORS[metric]}
               strokeWidth={2}
              />
            ))}
        </LineChart>
      );
    }

    if (chartType === 'area') {
      return (
        <AreaChart {...commonProps}>
          <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
          {commonAxisProps.xAxis}
          {commonAxisProps.yAxis}
          <Tooltip
            formatter={(value: any, name: string) => [value.toLocaleString(), name]}
            contentStyle={{ backgroundColor: 'white', borderRadius: '6px', border: '1px solid #e5e7eb' }}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            wrapperStyle={{ bottom: 50 }}
            verticalAlign="bottom"
            height={36}
          />
          {Object.keys(METRIC_COLORS)
            .filter((metric): metric is MetricType => metrics.includes(metric as MetricType))
            .map((metric) => (
              <Area
                key={metric}
                type="monotone"
                yAxisId={metric === 'impressions' ? 'left' : 'right'}
                dataKey={metric}
                name={METRIC_LABELS[metric]}
                stroke={METRIC_COLORS[metric]}
                fill={METRIC_COLORS[metric]}
               strokeWidth={2}
                fillOpacity={0.1}
              />
            ))}
        </AreaChart>
      );
    }

    return (
      <BarChart {...commonProps}>
        <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
        {commonAxisProps.xAxis}
        {commonAxisProps.yAxis}
        <Tooltip
          formatter={(value: any, name: string) => [value.toLocaleString(), name]}
          contentStyle={{ backgroundColor: 'white', borderRadius: '6px', border: '1px solid #e5e7eb' }}
          wrapperStyle={{ zIndex: 1000 }}
        />
        <Legend
          wrapperStyle={{ bottom: 50 }}
          verticalAlign="bottom"
          height={36}
        />
        {Object.keys(METRIC_COLORS)
          .filter((metric): metric is MetricType => metrics.includes(metric as MetricType))
          .map((metric) => (
            <Bar
              key={metric}
              yAxisId={metric === 'impressions' ? 'left' : 'right'}
              dataKey={metric}
              name={METRIC_LABELS[metric]}
              stroke={METRIC_COLORS[metric]}
              fill={METRIC_COLORS[metric]}
              maxBarSize={40}
            />
          ))}
      </BarChart>
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="mb-6">
        <h3 className="text-base md:text-lg font-semibold mb-4">Chart Settings</h3>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-4 sm:space-y-0">
          <div className="flex items-center space-x-2">
            <span className="text-xs md:text-sm font-medium text-gray-700">Chart Type:</span>
            <div className="flex space-x-2">
              {CHART_TYPES.map(({ type, icon, label }) => (
                <button
                  key={type}
                  onClick={() => onChartTypeChange(type)}
                  className={`inline-flex items-center px-2 md:px-3 py-1 md:py-1.5 border rounded-md text-xs md:text-sm ${
                    chartType === type
                      ? 'bg-indigo-50 border-indigo-300 text-indigo-700'
                      : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  {icon}
                  <span className="ml-1.5">{label}</span>
                </button>
              ))}
            </div>
          </div>
          
          <div className="flex items-center space-x-2">
            <span className="text-xs md:text-sm font-medium text-gray-700">Metrics:</span>
            <div className="flex flex-wrap gap-2">
              {AVAILABLE_METRICS.map(({ value, label }) => (
                <button
                  key={value}
                  onClick={() => onMetricToggle(value)}
                  className={`px-1.5 md:px-2 py-0.5 md:py-1 rounded-md text-xs md:text-sm ${
                    metrics.includes(value)
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-base md:text-lg font-semibold mb-4">Performance Over Time</h3>
        {formattedData.length > 0 ? (
          <div className="w-full" style={{ height: '400px' }}>
            <ResponsiveContainer width="100%" height={450}>
              {renderChart()}
            </ResponsiveContainer>
          </div>
        ) : (
          <p className="text-gray-500 text-center py-8">No data available to display</p>
        )}
      </div>
    </div>
  );
};