import React, { useEffect } from 'react';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { FileUpload } from './components/FileUpload';
import { MetricsChart } from './components/MetricsChart';
import { MetricsTable } from './components/MetricsTable';
import { ShareDropdown } from './components/ShareDropdown';
import { PDFReport } from './components/PDFReport';
import { parseCSV } from './utils/csvParser';
import { DashboardData, MetaAdMetrics, SavedDashboard, ChartType, MetricType } from './types/metrics';
import { FileText, Download, DollarSign, MousePointerClick, Users, Percent, CreditCard } from 'lucide-react';
import { env } from './config/env';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ErrorAlert } from './components/ErrorAlert';
import { DashboardLayout } from './components/DashboardLayout';
import { SharedDashboard } from './components/SharedDashboard';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Terms } from './pages/Terms';
import { LandingPage } from './components/LandingPage';
import { Dashboard } from './components/Dashboard';

export const STORAGE_KEY = 'meta-dashboards';

function App() {
  const [currentPath, setCurrentPath] = React.useState(window.location.pathname);

  React.useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('popstate', handleLocationChange);
    return () => window.removeEventListener('popstate', handleLocationChange);
  }, []);

  const renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isSharedView = searchParams.get('view') === 'shared';
    const sharedData = searchParams.get('data');

    if (isSharedView && sharedData) {
      try {
        const decodedData = JSON.parse(atob(sharedData));
        const name = searchParams.get('name');
        return <SharedDashboard data={decodedData} dashboardName={name} />;
      } catch (error) {
        return <div className="p-4">Invalid dashboard data</div>;
      }
    }

    switch (currentPath) {
      case '/privacy':
        return <PrivacyPolicy />;
      case '/terms':
        return <Terms />;
      default:
        return (
          <>
            <SignedOut>
              <LandingPage />
            </SignedOut>
            <SignedIn>
              <Dashboard />
            </SignedIn>
          </>
        );
    }
  };

  return (
    <ErrorBoundary>
      <ClerkProvider publishableKey={env.CLERK_PUBLISHABLE_KEY}>
        {renderContent()}
      </ClerkProvider>
    </ErrorBoundary>
  );
}

export default App;