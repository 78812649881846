import React, { useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format, parseISO } from 'date-fns';
import { DashboardData, MetricType } from '../types/metrics';
import { METRIC_COLORS } from './MetricsChart';
import { DollarSign, MousePointerClick, Users, Percent, CreditCard } from 'lucide-react';

interface PDFReportProps {
  data: DashboardData;
  dashboardName?: string;
  chartType?: ChartType;
  selectedMetrics?: MetricType[];
}

const formatNumber = (num: number) => num.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const PDFReport: React.FC<PDFReportProps> = ({ 
  data, 
  dashboardName, 
  chartType = 'bar',
  selectedMetrics = ['impressions', 'results']
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    generatePDF();
  }, [data, chartType, selectedMetrics]);

  const generatePDF = () => {
    const doc = new jsPDF('landscape');
    
    const addPageLogo = () => {
      const img = document.querySelector('img[alt="Dashling"]') as HTMLImageElement;
      if (img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        
        // Set up dimensions and positioning
        const pageWidth = doc.internal.pageSize.width;
        const logoWidth = 16;
        const logoHeight = 4;
        const textWidth = doc.getTextWidth('Powered by');
        const spacing = 1; // Minimal spacing between text and logo
        const totalWidth = textWidth + spacing + logoWidth;
        const startX = (pageWidth - totalWidth) / 2;
        
        // Add "Powered by" text
        doc.setFontSize(8);
        doc.setTextColor(156, 163, 175);
        doc.text('Powered by', startX, 8);
        
        // Add logo after text with spacing
        doc.addImage(canvas.toDataURL(), 'PNG', startX + textWidth + spacing, 5, logoWidth, logoHeight);
      }
    };
    
    addPageLogo();
    
    const title = dashboardName || 'Unknown Report';
    doc.setFontSize(24);
    doc.text(title, 15, 20);
    
    // Date range in top right
    const startDate = format(parseISO(data.metrics[0].reporting_starts), 'MMM d, yyyy');
    const endDate = format(parseISO(data.metrics[data.metrics.length - 1].reporting_starts), 'MMM d, yyyy');
    doc.setFontSize(10);
    doc.text(`Period: ${startDate} - ${endDate}`, doc.internal.pageSize.width - 15, 20, { align: 'right' });

    // Metrics Cards
    const cardWidth = 50;
    const cardHeight = 25;
    const startY = 30;
    const gap = 5;
    
    // Card backgrounds
    for (let i = 0; i < 5; i++) {
      doc.setFillColor(255, 255, 255);
      doc.setDrawColor(229, 231, 235);
      doc.roundedRect(15 + i * (cardWidth + gap), startY, cardWidth, cardHeight, 3, 3, 'FD');
    }
    
    // Card content
    doc.setFontSize(10);
    doc.setTextColor(75, 85, 99);
    
    const metrics = [
      { label: 'Total Spend', value: `${formatNumber(data.summary.totalAmountSpent)} DKK`, color: '#4F46E5' },
      { label: 'Total Results', value: `${formatNumber(data.summary.totalResults)} clicks`, color: '#059669' },
      { label: 'Total Reach', value: formatNumber(data.summary.totalReach), color: '#2563EB' },
      { label: 'Average CTR', value: `${((data.summary.totalResults / data.summary.totalImpressions) * 100).toFixed(2)}%`, color: '#D97706' },
      { label: 'Average CPC', value: `${(data.summary.totalAmountSpent / data.summary.totalResults).toFixed(2)} DKK`, color: '#7C3AED' }
    ];

    metrics.forEach((metric, i) => {
      const x = 15 + i * (cardWidth + gap);
      doc.setTextColor(75, 85, 99);
      doc.text(metric.label, x + 5, startY + 8);
      doc.setTextColor(metric.color);
      doc.setFontSize(12);
      doc.text(metric.value, x + 5, startY + 18);
    });

    // Performance Chart
    doc.setFontSize(16);
    doc.setTextColor(31, 41, 55);
    doc.text('Performance Over Time', 15, startY + cardHeight + 20);

    // Draw chart
    const chartStartY = startY + cardHeight + 30;
    const chartHeight = 100;
    const chartWidth = 260;
    
    // Chart background
    doc.setFillColor(255, 255, 255);
    doc.setDrawColor(229, 231, 235);
    doc.roundedRect(15, chartStartY, chartWidth, chartHeight, 3, 3, 'FD');
    
    // Calculate max values first
    const metrics_data = data.metrics.slice(-7);
    const maxValues = selectedMetrics.reduce((acc, metric) => {
      let maxVal = 0;
      if (metric === 'ctr') {
        maxVal = Math.max(...metrics_data.map(m => (m.results / m.impressions) * 100));
      } else if (metric === 'cpc') {
        maxVal = Math.max(...metrics_data.map(m => m.amount_spent / m.results));
      } else {
        maxVal = Math.max(...metrics_data.map(m => m[metric]));
      }
      return { ...acc, [metric]: maxVal };
    }, {} as Record<string, number>);
    
    // Draw axes
    const yAxisGap = 20; // Space between y-axis labels
    doc.setDrawColor(209, 213, 219);
    
    // Y-axes
    doc.line(25, chartStartY + chartHeight - 10, 25, chartStartY + 10);
    doc.setFontSize(8);

    selectedMetrics.forEach((metric, idx) => {
      doc.setTextColor(METRIC_COLORS[metric]);
      for (let i = 0; i <= 5; i++) {
        const value = maxValues[metric] * (i / 5);
        const y = chartStartY + chartHeight - 10 - (i * (chartHeight - 30) / 5);
        
        if (idx === 0) {
          // Left axis
          doc.text(formatNumber(value), 23, y, { align: 'right' });
          doc.setDrawColor(229, 231, 235);
          doc.line(25, y, chartWidth - 15, y); // Grid line
        } else if (idx === selectedMetrics.length - 1) {
          // Right axis
          doc.text(formatNumber(value), chartWidth - 5, y, { align: 'left' });
        }
      }
    });
    
    // X-axis
    doc.line(25, chartStartY + chartHeight - 10, chartWidth - 15, chartStartY + chartHeight - 10); // X-axis
    
    // Plot data based on chart type
    if (chartType === 'bar') {
      const barWidth = ((chartWidth - 50) / metrics_data.length / selectedMetrics.length) * 0.6;
      metrics_data.forEach((metric, i) => {
        const x = 35 + i * ((chartWidth - 50) / metrics_data.length);
        
        selectedMetrics.forEach((metricType, idx) => {
          let value = 0;
          if (metricType === 'ctr') {
            value = (metric.results / metric.impressions) * 100;
          } else if (metricType === 'cpc') {
            value = metric.amount_spent / metric.results;
          } else {
            value = metric[metricType];
          }
          
          const height = (value / maxValues[metricType]) * (chartHeight - 30);
          doc.setFillColor(METRIC_COLORS[metricType]);
          const barX = x + (barWidth * (idx + 0.5));
          doc.rect(barX, chartStartY + chartHeight - 10 - height, barWidth, height, 'F');
        });
        
        // Labels
        doc.setFontSize(8);
        doc.setTextColor(107, 114, 128);
        
        // Word wrap campaign name
        const campaignName = metric.ad_set_name || 'Unknown Campaign';
        const words = campaignName.split(' ');
        let lines = [];
        let currentLine = '';
        
        words.forEach(word => {
          const testLine = currentLine ? `${currentLine} ${word}` : word;
          if (testLine.length <= 15) {
            currentLine = testLine;
          } else {
            if (currentLine) lines.push(currentLine);
            currentLine = word;
          }
        });
        if (currentLine) lines.push(currentLine);
        
        // Draw wrapped text
        lines.forEach((line, lineIndex) => {
          doc.text(line, x, chartStartY + chartHeight + 5 + (lineIndex * 4), { align: 'center' });
        });
      });
    } else {
      // Line or Area chart
      const points = metrics_data.map((metric, i) => {
        const point: any = {
          x: 35 + i * ((chartWidth - 50) / (metrics_data.length - 1))
        };
        
        selectedMetrics.forEach(metricType => {
          let value = 0;
          if (metricType === 'ctr') {
            value = (metric.results / metric.impressions) * 100;
          } else if (metricType === 'cpc') {
            value = metric.amount_spent / metric.results;
          } else {
            value = metric[metricType];
          }
          point[metricType] = chartStartY + chartHeight - 10 - (value / maxValues[metricType]) * (chartHeight - 30);
        });
        
        return point;
      });
      
      // Draw area fills if area chart
      if (chartType === 'area') {
        selectedMetrics.forEach(metricType => {
          doc.setFillColor(METRIC_COLORS[metricType]);
          doc.setLineWidth(1);
          doc.setGState(new doc.GState({ opacity: 0.1 }));
          doc.moveTo(points[0].x, chartStartY + chartHeight - 10);
          points.forEach(point => doc.lineTo(point.x, point[metricType]));
          doc.lineTo(points[points.length - 1].x, chartStartY + chartHeight - 10);
          doc.fill();
        });
        doc.setGState(new doc.GState({ opacity: 1 }));
      }
      
      // Draw lines
      points.forEach((point, i) => {
        if (i < points.length - 1) {
          selectedMetrics.forEach(metricType => {
            doc.setLineWidth(1);
            doc.setDrawColor(METRIC_COLORS[metricType]);
            doc.line(point.x, point[metricType], points[i + 1].x, points[i + 1][metricType]);
          });
        }
        
        // Campaign name labels
        doc.setFontSize(8);
        doc.setTextColor(107, 114, 128);
        
        // Word wrap campaign name
        const campaignName = metrics_data[i].ad_set_name || 'Unknown Campaign';
        const words = campaignName.split(' ');
        let lines = [];
        let currentLine = '';
        
        words.forEach(word => {
          const testLine = currentLine ? `${currentLine} ${word}` : word;
          if (testLine.length <= 15) {
            currentLine = testLine;
          } else {
            if (currentLine) lines.push(currentLine);
            currentLine = word;
          }
        });
        if (currentLine) lines.push(currentLine);
        
        // Draw wrapped text
        lines.forEach((line, lineIndex) => {
          doc.text(line, point.x, chartStartY + chartHeight + 5 + (lineIndex * 6), { align: 'center' });
        });
      });
    }

    const METRIC_LABELS = {
      impressions: 'Impressions',
      results: 'Results',
      amount_spent: 'Amount Spent',
      reach: 'Reach',
      ctr: 'CTR',
      cpc: 'CPC'
    };

    // Add legend below chart
    const legendY = chartStartY + chartHeight + 15;
    const legendSpacing = 50;
    
    selectedMetrics.forEach((metric, idx) => {
      const x = 15 + (idx * legendSpacing);
      doc.setFillColor(METRIC_COLORS[metric]);
      doc.rect(x, legendY, 8, 4, 'F');
      doc.setTextColor(31, 41, 55);
      doc.setFontSize(8);
      doc.text(METRIC_LABELS[metric], x + 12, legendY + 3);
    });

    // Start new page for Campaign Details
    doc.addPage();
    addPageLogo();
    doc.setFontSize(16);
    doc.text('Campaign Details', 15, 20);

    // Calculate totals
    const totals = {
      impressions: data.metrics.reduce((sum, m) => sum + m.impressions, 0),
      results: data.metrics.reduce((sum, m) => sum + m.results, 0),
      amount_spent: data.metrics.reduce((sum, m) => sum + m.amount_spent, 0),
      reach: data.metrics.reduce((sum, m) => sum + m.reach, 0),
    };

    // Prepare table data including metrics and totals row
    const metricsRows = data.metrics.map(metric => [
      format(parseISO(metric.reporting_starts), 'MMM d, yyyy'),
      metric.ad_set_name,
      formatNumber(metric.impressions),
      `${formatNumber(metric.results)} clicks`,
      `${formatNumber(metric.amount_spent)} DKK`,
      `${formatNumber(metric.cost_per_results)} DKK`,
      `${((metric.results / metric.impressions) * 100).toFixed(2)}%`,
      `${(metric.amount_spent / metric.results).toFixed(2)} DKK`,
    ]);

    // Add totals row
    const totalsRow = [
      '',
      'Total',
      formatNumber(totals.impressions),
      `${formatNumber(totals.results)} clicks`,
      `${formatNumber(totals.amount_spent)} DKK`,
      `${(totals.amount_spent / totals.results).toFixed(2)} DKK`,
      `${((totals.results / totals.impressions) * 100).toFixed(2)}%`,
      `${(totals.amount_spent / totals.results).toFixed(2)} DKK`,
    ];

    const tableData = [...metricsRows, totalsRow];

    autoTable(doc, {
      startY: 30,
      head: [['Date', 'Ad Set', 'Impressions', 'Results', 'Amount Spent', 'Cost per Click', 'CTR', 'CPC']],
      body: metricsRows,
      foot: [totalsRow],
      theme: 'striped',
      headStyles: { fillColor: [79, 70, 229] },
      styles: { fontSize: 8, lineHeight: 1.2 },
      bodyStyles: { lineHeight: 1.2 },
      footStyles: { 
        fillColor: [243, 244, 246], 
        fontStyle: 'bold',
        textColor: [0, 0, 0]
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 40 },
      }
    });

    // Save the PDF in the container
    if (containerRef.current) {
      containerRef.current.innerHTML = '';
      const pdfBlob = doc.output('bloburl');
      const iframe = document.createElement('iframe');
      iframe.src = pdfBlob;
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = 'none';
      containerRef.current.appendChild(iframe);
    }
  };

  return (
    <div ref={containerRef} className="w-full h-[calc(100vh-12rem)]" />
  );
};