import React from 'react';
import { Sidebar } from './Sidebar';
import { SavedDashboard } from '../types/metrics';

interface DashboardLayoutProps {
  children: React.ReactNode;
  savedDashboards: SavedDashboard[];
  onDashboardSelect: (dashboard: SavedDashboard) => void;
  onNewDashboard: () => void;
  onDashboardDelete: (id: string) => void;
  activeDashboardId?: string;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  savedDashboards,
  onDashboardSelect,
  onNewDashboard,
  onDashboardDelete,
  activeDashboardId,
}) => {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        savedDashboards={savedDashboards}
        onDashboardSelect={onDashboardSelect}
        onNewDashboard={onNewDashboard}
        onDashboardDelete={onDashboardDelete}
        activeDashboardId={activeDashboardId}
      />
      <main className="flex-1 overflow-y-auto">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};