import React from 'react';
import { Link } from './Link';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-indigo-600 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <img src="/assets/images/dashling-logo_white.png" alt="Dashling" className="h-6 mx-auto mb-4" />
        <div className="flex justify-center items-center space-x-6 mb-4">
          <Link href="/privacy" className="text-sm text-indigo-200 hover:text-white">
            Privacy Policy
          </Link>
          <Link href="/terms" className="text-sm text-indigo-200 hover:text-white">
            Terms of Service
          </Link>
        </div>
        <p className="text-indigo-200/80 text-sm">
          © {new Date().getFullYear()} Dashling
        </p>
      </div>
    </footer>
  );
};