import Papa from 'papaparse';
import { MetaAdMetrics } from '../types/metrics';
import { normalizeFieldName } from './csvFieldMapping';

const validateRequiredFields = (headers: string[]): string[] => {
  const requiredFields = [
    'reporting_starts',
    'ad_set_name',
    'impressions',
    'results',
    'amount_spent'
  ];
  return requiredFields.filter(field => !headers.includes(field));
};

const parseNumber = (value: string | number | undefined): number => {
  if (typeof value === 'undefined' || value === '') return 0;
  if (typeof value === 'number') return value;
  // Remove percentage sign and convert to number
  return Number(value.toString().replace('%', '')) || 0;
};

export const parseCSV = (file: File): Promise<MetaAdMetrics[]> => {
  return new Promise((resolve, reject) => {
    if (!file.name.toLowerCase().endsWith('.csv')) {
      reject(new Error('Please upload a valid CSV file'));
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: normalizeFieldName,
      complete: (results) => {
        if (results.errors.length > 0) {
          console.warn('CSV parsing warnings:', results.errors);
        }

        // Get normalized headers
        const headers = Object.keys(results.data[0] || {}).map(normalizeFieldName);
        const missingFields = validateRequiredFields(headers);

        if (missingFields.length > 0) {
          console.warn(`Warning: Missing fields: ${missingFields.join(', ')}`);
          console.warn('Headers found:', headers.join(', '));
        }

        const metrics: MetaAdMetrics[] = results.data
          .filter((row: any) => row.reporting_starts && row.ad_set_name)
          .map((row: any) => ({
            reporting_starts: row.reporting_starts,
            reporting_ends: row.reporting_ends,
            ad_set_name: row.ad_set_name || 'Unknown Campaign',
            ad_set_delivery: row.ad_set_delivery || '',
            bid: Number(row.bid) || 0,
            bid_type: row.bid_type || '',
            ad_set_budget: Number(row.ad_set_budget) || 0,
            ad_set_budget_type: row.ad_set_budget_type || '',
            last_significant_edit: row.last_significant_edit || '',
            attribution_setting: row.attribution_setting || '',
            results: parseNumber(row.results) || 0,
            result_indicator: row.result_indicator || '',
            reach: parseNumber(row.reach),
            impressions: parseNumber(row.impressions),
            cost_per_results: parseNumber(row.cost_per_results),
            amount_spent: parseNumber(row.amount_spent),
            ends: row.ends,
            starts: row.starts,
          }));
        
        if (metrics.length === 0) {
          reject(new Error('The CSV file is empty or contains no valid data'));
        } else {
          resolve(metrics);
        }
      },
      error: (error) => reject(new Error('Failed to parse the CSV file: ' + error.message)),
    });
  });
};