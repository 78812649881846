const CLERK_DOCS_URL = 'https://dashboard.clerk.com/last-active?path=api-keys';

export const env = {
  CLERK_PUBLISHABLE_KEY: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY as string,
} as const;

const requiredEnvVars = ['CLERK_PUBLISHABLE_KEY'] as const;

function validateEnvVar(key: string, value: string | undefined) {
  if (!value || value.trim() === '') {
    throw new Error(
      `Missing or empty environment variable: ${key}\n` +
      `Please check your .env file and ensure it contains:\n` +
      `VITE_CLERK_PUBLISHABLE_KEY=your_key\n\n` +
      `You can get your Clerk Publishable Key at:\n${CLERK_DOCS_URL}`
    );
  }

  if (key === 'CLERK_PUBLISHABLE_KEY') {
    if (!value.startsWith('pk_test_') && !value.startsWith('pk_live_')) {
      throw new Error(
        `Invalid Clerk Publishable Key format.\n` +
        `The key must start with "pk_test_" or "pk_live_".\n` +
        `Please check your key at:\n${CLERK_DOCS_URL}`
      );
    }
  }
}

for (const envVar of requiredEnvVars) {
  validateEnvVar(envVar, env[envVar]);
}