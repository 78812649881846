import React from 'react';
import { SignUpButton, SignInButton } from '@clerk/clerk-react';
import { Link } from './Link';

export const Header: React.FC = () => {
  return (
    <header className="fixed w-full top-0 bg-white/90 backdrop-blur-sm z-50 border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link href="/" className="flex items-center space-x-2">
            <img src="/assets/images/dashling-logo.png" alt="Dashling" className="h-8" />
          </Link>
          <div className="flex items-center space-x-4">
            <SignInButton mode="modal">
              <button className="text-gray-600 hover:text-gray-900 font-medium px-4 py-2">
                Sign In
              </button>
            </SignInButton>
            <SignUpButton mode="modal">
              <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 transition-all">
                Sign Up Free
              </button>
            </SignUpButton>
          </div>
        </div>
      </div>
    </header>
  );
};