import React from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

export const Terms: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header />
      <main className="flex-1 max-w-3xl mx-auto px-4 pt-24 pb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        
        <div className="prose prose-indigo">
          <p className="text-gray-600 mb-6">Last updated: {new Date().toLocaleDateString()}</p>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-600 mb-4">
              By accessing and using Dashling, you accept and agree to be bound by the terms
              and conditions of this agreement.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Use License</h2>
            <p className="text-gray-600 mb-4">
              We grant you a personal, non-exclusive, non-transferable license to use Dashling
              in accordance with these terms.
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>For personal or business use only</li>
              <li>Not to be modified or redistributed</li>
              <li>Subject to compliance with these terms</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Limitations</h2>
            <p className="text-gray-600 mb-4">
              You may not:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Use the service for any illegal purpose</li>
              <li>Attempt to gain unauthorized access</li>
              <li>Interfere with the service's operation</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Contact</h2>
            <p className="text-gray-600">
              Questions about the Terms of Service should be sent to:
              <br />
              <a href="mailto:legal@dashling.app" className="text-indigo-600 hover:text-indigo-800">
                legal@dashling.app
              </a>
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};